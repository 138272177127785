import React from 'react';
import Hero from '../Sheare/Hero';

const TermsCondition = () => {
    return (
        <div className='mt-20'>
            <Hero></Hero>
        </div>
    );
};

export default TermsCondition;