

  const  PortItems = [
        {
            id:1,
            name:'app1',
            ima :"https://i.ibb.co/H7nCgpS/app1.jpg",
            category : 'app',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:2,
            name:'app2',
            ima :"https://i.ibb.co/tDBY7fx/app2.jpg",
            category : 'app',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:3,
            name:'app3',
            ima :"https://i.ibb.co/qMTchFW/app3.jpg",
            category : 'app',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:4,
            name:'Card1',
            ima :"https://i.ibb.co/6B0nzBv/card1.jpg",
            category : 'card',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:5,
            name:'Card2',
            ima :"https://i.ibb.co/TT5XzbJ/card2.jpg",
            category : 'card',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:6,
            name:'Card3',
            ima :"https://i.ibb.co/dpD6NDK/card3.jpg",
            category : 'card',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:7,
            name:'Web1',
            ima :"https://i.ibb.co/t8JtD9N/web1.jpg",
            category : 'web',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:8,
            name:'Web2',
            ima :"https://i.ibb.co/L5hLhhq/web2.jpg",
            category : 'web',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        },
        {
            id:9,
            name:'Web3',
            ima :"https://i.ibb.co/gFBv4m5/web3.jpg",
            category : 'web',
            url1:"https://zerodevs.com/",
            url2:"https://zerodevs.com/"
        }
    ]


    const Testimonials = [
        {
            id:1,
            image:'https://i.ibb.co/dJ3HXJ7/testimonial-1.jpg',
            name:'Shara Williams',
            title:'Designer',
            description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        },
        {
            id:2,
            image:'https://i.ibb.co/9t6jcVB/testimonial-2.jpg',
            name:'Shaul Goodman',
            title:'Developer',
            description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        },
        {
            id:3,
            image:'https://i.ibb.co/XWYP5pn/testimonial-3.jpg',
            name:'Jena Karlis',
            title:'store Owner',
            description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        },
        {
            id:4,
            image:'https://i.ibb.co/Kyxtys1/testimonial-4.jpg',
            name:'Matt Brandon',
            title:'Freelancer',
            description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        },
        {
            id:5,
            image:'https://i.ibb.co/1Ms6X0x/testimonial-5.jpg',
            name:'John Larson',
            title:'Entrepreneur',
            description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
        }
    ]


// export default PortItems;
export {PortItems,Testimonials}