const clientImages = [
    {
        id:1,
        image:'https://i.ibb.co/mBcbyQF/client-1.png'
    },
    {
        id:2,
        image:'https://i.ibb.co/CP1qhdc/client-2.png'
    },
    {
        id:3,
        image:'https://i.ibb.co/ngTmBPw/client-3.png'
    },
    {
        id:4,
        image:'https://i.ibb.co/GFx6vS8/client-4.png'
    },
    {
        id:5,
        image:'https://i.ibb.co/NZr4sj1/client-5.png'
    },
    {
        id:6,
        image:'https://i.ibb.co/82CbKV8/client-6.png'
    },
    {
        id:7,
        image:'https://i.ibb.co/RQGpfBd/client-7.png'
    },
]

export default clientImages;