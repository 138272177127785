import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Elements from './ServiceElements';

const Services = (props) => {

  const [services, setServices] = useState(Elements);
  const [error,setError] = useState('');

  // console.log(error);
  // useEffect(() => {
  //   var requestOptions = {
  //     method: 'GET',
  //     redirect: 'follow'
  //   };

  //   fetch("http://codefotech.com:18520/service", requestOptions)
  //     .then(response => response.json())
  //     .then(result => setServices(result))
  //     .catch(error => setError('This section is under construction'));
   
  // }, [])

  // let changeClasses = [];
  // console.log(changeClasses);
  // services.map(service => {
  //   const lastDigit = service._id.toString().slice(-1);

  //   if (lastDigit % 2 == 0) {
  //     changeClasses.push('col-md-6 col-lg-5 wow bounceInUp offset-lg-1');
  //   } else {
  //     changeClasses.push('col-md-6 col-lg-5 wow bounceInUp');
  //   }
  // })
  console.log(services);
  return (
    <div>
      <section id="services" class="section-bg">
        <div class="container">

          <header class="section-header">
            <h3>Services</h3>
            <p>We not only provide development & support services of software or apps but we provide a finished solution which will help our clients to grow their businesses. </p>
          </header>

          <div data-aos={props.aos} data-aos-offset={props.aos_offset} class="row">

            {
              services.map(service => {
                // daynamic style

                // const lastDigit = service._id.toString().slice(-1);
                let changeClasses = [];
                if (service.id % 2 !== 0) {
                  changeClasses.push('col-md-6 col-lg-5 wow bounceInUp offset-lg-1');
                } else {
                  changeClasses.push('col-md-6 col-lg-5 wow bounceInUp');
                }

                return (
                  <div data-aos="fade-right" class=" " className={changeClasses} >
                    <div class="box">
                      <div class="icon"><img src={service?.image} alt="" className='h-14' /></div>
                      <h4 class="title"><a href="">{service?.name}</a></h4>
                      <p class="description">{service?.description}</p>
                    </div>
                  </div>
                )
                
              })
            }

          </div>
            {
              error &&
                <p className='error fst-italic shadow-md bg-success bg-opacity-10'>{error}</p>
              }
            
        </div>
      </section>
    </div>
  );
};

export default Services;