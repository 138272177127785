const pictures = [
    {
        id:1,
        image:'https://i.ibb.co/R0yxrG1/team-1-1.jpg',
        title:'Founder & CEO',
        name:'Al Mustakim'
    },
    {
        id:2,
        image:'https://i.ibb.co/tL33THz/team-3-1.png',
        title:'Senior Project Manager',
        name:'Faisal Ahmed'
    },
    {
        id:3,
        image:'https://i.ibb.co/zhkRWV9/Seam.png',
        title:'Back-end Developer',
        name:'Seam Uddin'
    },
    {
        id:4,
        image:'https://i.ibb.co/54Yrg3M/mehedi.jpg',
        title:'Junior iOS Engineer',
        name:'Md. Mehedi Hasan'
    },
    {
        id:5,
        image:'https://i.ibb.co/K7BYHFN/job-Pic-removebg-preview.png',
        // image:'https://i.ibb.co/bdTVwLq/DSC-0082.jpg',
        title:'Junior Full-stack Developer',
        name:'Md. Lutfor Rahman'
    },
    {
        id:6,
        image:'https://i.ibb.co/WGwbjdM/rafi.jpg',
        title:'UI/UX Designer',
        name:'Rafi Chowdhury'
    },
    {
        id:7,
        image:'https://i.ibb.co/fnRSJkG/team-4.jpg',
        title:'UI/UX Designer',
        name:'Shara Williams'
    },
]

export default pictures;