import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import clientImages from './ClientsElements';

const Client = (props) => {

  const [clients, setClients] = useState(clientImages);
  const [error, setError] = useState('');
//   console.log(clients);

//   useEffect(() => {

//     var requestOptions = {
//       method: 'GET',
//       redirect: 'follow'
//     };
    
//     fetch("http://codefotech.com:18520/client", requestOptions)
//       .then(response => response.json())
//       .then(result => setClients(result))
//       .catch(error => setError('This section is under construction'));

//  }, [])
  return (
    <div className=''>
      <section id="clients" class="section-bg">

        <div class="container">

          <div class="section-header">
            <h3>Our Clients</h3>
            <p>Our clients are dispersed all over the globe. We are resolute in providing you quality service & maintaining the standard doubtlessly .</p>
          </div>

          <div data-aos={props.aos} data-aos-offset={props.aos_offset} class="row ">

            {
              clients.map(client => {
                return (
                  <div data-aos="fade-right" class="col-lg-3 col-md-4 col-xs-6 mt-4">
                    <div class="client-logo">
                      <img src={client.image} class="img-fluid" alt="" />
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            error &&
            <p className='error fst-italic shadow-md bg-success bg-opacity-10'>{error}</p>
          }
        </div>
      </section>
      {/* <div class="section-header">
            <h3>Our Clients</h3>
            <p>Our clients are dispersed all over the globe. We are resolute in providing you quality service & maintaining the standard doubtlessly .</p>
          </div>
          <div data-aos={props.aos} data-aos-offset={props.aos_offset} class="row ">
          {
              clients.map(client => {
                return (
                  <div data-aos="" class="col-12 col-lg-6 mt-4">
                    <div class="">
                      <img src={client.image} class="img-fluid" alt="" />
                    </div>
                  </div>
                )
              })
            }
          </div> */}
    </div>
  );
};

export default Client;