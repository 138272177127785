const Elements = [
    
    {
        id:1,
        name:'Finance Solution',
        image:'https://i.ibb.co/0D7vSb3/icons8-clock-50.png',
        description:'Set up your expenses and budget tracking. Download Reach mobile app! Get rewards for managing your finances, Get clarity into your spending habits'
    },
    {
        id:2,
        name:'Business Solution',
        image:'https://i.ibb.co/fkmS33W/icons8-world-64.png',
        description:'WhatsApp Business products support businesses from large to small, see which product best fits your needs'
    },
    {
        id:3,
        name:'On-demand Solution',
        image:'https://i.ibb.co/8Yvtx9M/icons8-page-64.png',
        description:'useful mobile apps aim to target customers services.Creating the perfect buying environment where people can be sure about the quality,'
    },
    {
        id:4,
        name:' E-commerce Solution',
        image:'https://i.ibb.co/3d7kHFj/icons8-combo-chart-100.png',
        description:'To get your products to your buyer easily and conveniently. Not only can you set your own, such as a price for collection.'
    },
    {
        id:5,
        name:'Mobile Application',
        image:'https://i.ibb.co/0hTRzNv/icons8-service-50.png',
        description:'No matter how much your business grows, you will always be able to take free unlimited orders with zero costs.'
    },
    {
        id:6,
        name:'Hospital Management',
        image:'https://i.ibb.co/q97j9rb/icons8-service-64.png',
        description:'Track engagement and retention to better understand your target audience and growth potential in specific markets'
    },
]

export default  Elements;