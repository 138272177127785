const choosesElements = [
    {
        id:1,
        image:'https://i.ibb.co/McyqB1j/icons8-diamond-50.png',
        title:'A Plan for Success',
        description:'You want results. We have found that the best way to get them is with up front research target market and customer psychographics'
    },
    {
        id:2,
        image:'https://i.ibb.co/Q6JHS1R/icons8-english-64.png',
        title:'Creativity',
        description:'We bring our diverse background of advertising, design, branding, public relations, research and strategic planning to work for your company'
    },
    {
        id:3,
        image:'https://i.ibb.co/X338PGz/icons8-compose-80.png',
        title:'Experts Only',
        description:'First Impressions is comprised of specialists with corporate and agency experience that hail from various backgrounds.'
    },
]

export default choosesElements;